.feed {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  top: 40px;
  z-index: 1;
  width: 50%;
  left: 25%;
  padding: 60px;
}

.feed-test {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 40px;
}

.dotDotDot {
  display: flex;
  justify-content: flex-end;
}

.pictures {
  position: relative;
  right: 6%;
}

.user-post {
  height: 75%;
  width: 75%;
}

.post-card-feed {
  width: 500px;
  /* removed height to dynamically change with comment length and errors validation -ms  */
  /* height: 820px; */
  border: 1px solid rgba(128, 128, 128, 0.302);
  border-radius: 2%;
  margin-bottom: 15px;
  background-color: black;
  overflow-y: auto;
  overflow-x: hidden;
}

.user-profile-info-feed {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(128, 128, 128, 0.129);
  padding: 10px 5px;
  align-items: center;
  width: 100%;
}

.user-profile-info {
  display: flex;
  align-items: center;
}

.user-profile-pic-feed {
  margin: 0px 10px;
  /* padding-right: 13px; */
}

.feed-post-image {
  height: 70%;
  width: 100%;
  border-bottom: 1px solid rgba(128, 128, 128, 0.154);
}

.user-post-image {
  width: 100%;
  height: 400px;
  object-fit: contain;
}

.likes-post-feed {
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
}

.feedHowManyLikes {
  padding-left: 13px;
  cursor: pointer;
  width: fit-content;

}

.post-caption-feed {
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  /* align-items: center; */
}

.user-profile-pic-caption {
  height: 25px;
  width: 25px;
  border-radius: 50px;
  margin: 0px 12px;
}

.bottom-post-feed {
  /* height: ; */
}

#id-nav {
  padding-left: 14px;

}

#id-nav:hover {
  text-decoration: underline;
}

/*MODAL CSS STARTS HERE */

.postOptionsModalBckgFeed {
  background-color: rgba(0, 0, 0, 0.504);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
}


.actualModalComponentFeed {
  position: fixed;
  background-color: rgb(29, 29, 29);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: 40%;
  top: 20%;
  width: 300px;
  height: fit-content;
  border: 1px solid rgba(128, 128, 128, 0.346);
  border-radius: 5px;
  z-index: 40;
  animation: grow 0.3s forwards;

}

@keyframes grow {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

.cancelPostButtonFeed {
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  justify-content: center;
  padding: 10px 0px;

}

@keyframes grow {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

.cancelPostButtonFeed {
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  justify-content: center;
  padding: 10px 0px;
}

.bothFollowsBigDiv {
  background-color: #262626;
  border: none;
}
