#full-page-container {
    /* border: white solid 5px; */
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


/* this holds everything above the footer and centers it */
#top-page-container {
    /* position: absolute; */
    /* border: greenyellow solid 4px; */
    max-height: 90%;
    width: 100vw;
    display: flex;
    flex-direction: center;
    justify-content: center;
    align-items: center;
}


#right-container {
    /* border: royalblue solid 3px; */
    height: 80vh;
    width: 370px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#l-border-div{
    width:360px;
    /* min-height: 360px; */
    border: #363636 solid 1px;
    background-color: #000000;
}

/* Google font that says Pixtagram */
#heading-div {
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: olivedrab solid 3px; */
    width: 350px;
    height: 100px;
    /* font-size: 50px; */
    background-color: #000000;
    margin-top: 20px;
    margin-bottom: 5px;
}

#heading-text{
    font-family: 'Oleo Script', cursive;
    font-size: 40px;
    color: white
}

/* inputs */
.input-label {
    display: none;
}

.l-input {
    width: 300px;
    height: 30px;
    border-radius: 2px;
    border: #585858 solid 1px;
    margin: 5px;
    background-color: #121212;
    color: white;
    padding-left: 5px;
}

.l-input:focus{
    outline: none;
}

.l-input::placeholder{
    padding-left: 5px;
}

#login-form-div {
    height: 150px;
    width: 350px;
    /* border: orange solid 3px; */
    background-color: #000000;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

placeholder{
    margin-left: 5px;
}

#login-button {
    border: none;
    border-radius: 2px;
    height: 30px;
    background-color: #3795F6;
    color: white;
    width: 300px;
    margin-left: 5px;
    margin-top: 6px;
    font-weight: bold;
    margin-bottom: 5px;
}

#login-button:disabled,
#login-button[disabled] {
    background-color: #072641;
    color: gray;
    cursor: default;
}

#errors {
  color: rgb(196, 30, 30);
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
}

.l-or-divider{
    /* border: yellow solid 3px; */
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    width: 350px;
    background-color: #000000;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    /* margin-top: 20px; */
}



.l-line{
    width: 100px;
    height: 1px;
    background-color: #8e8e8e;
}

.l-demo-div {
    /* border: blue solid 3px; */
    padding-top: 10px;
    height: 40px;
    width: 350px;
    background-color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
}

#l-demo-button{
    width: 255px;
    height: 30px;
    font-size: 16px;
    font-weight: bold;
    background-color: #000000;
    border: none;
    color: #a7a7a7;
    transition: color .6s;
    cursor: pointer;
    margin-bottom: 15px;
}

#l-demo-button:hover{
    color: white;
}

#b-line{
    width: 300px;
    height: 1px;
    background-color: #4c516d;
}

#l-signup-div {
    margin-top: 15px;
    background-color: #000000;
    border: #363636 solid 1px;
    height: 53px;
    width: 358px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
}

#l-text{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    color: white;
    font-weight: bold;
    /* font-size: 50px; */
}

#s-link{
    text-decoration: none;
    color: #0095F6;
    font-weight: bold;
}

#footer {
    /* position: absolute; */
    /* border: brown solid 4px; */
    margin-bottom: 5px;
    height: 5%;
    width: 60vw;
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    flex-direction: row;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
}

#creators{
    /* border: pink solid 3px; */
    margin-top: 5px;
    height: 5%;
    width: 600px;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-direction: row;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    color: #8E8E8E;
    text-decoration: none;
}

.c-name {
    color: #8E8E8E;
    text-decoration: none;
}

.c-name {
    color: #8E8E8E;
}

.c-name:visited{
    text-decoration: none;
    color: #8E8E8E;
}

/* This container will hold the images left of the login form */

.left-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: goldenrod solid 3px; */
    padding: 0px;
    width: 450px;
}

#image {
    width: 100%;
    height: 100%;
}

.login-error-div{
    margin: 10px;
}
