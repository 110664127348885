* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
}
/* containers the profile pic and the user info */
#header-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  /* border: pink solid 1px; */
  width: 100%;
  height: 160px;
  color: white;
  margin-bottom: 44px;
}

#profile-container {
  padding-top: 100px;
  /* border: orange solid 1px; */
  height: 100vh;
  width: 100vw;
  font-family: Arial, Helvetica, sans-serif;
  /* display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; */
}

/* #profile-pic-border{
    border:1px solid rgb(143, 143, 143);
    border-radius: 50%;
} */

.profile-pic {
  /* border: red solid 1px; */
  /* border-radius: 50%; */
  height: 150px;
  width: 300px;
  margin-right: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.posts-followers {
  /* border: blue 1px solid; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 25px;
  height: 20px;
  white-space: nowrap;
}

.p-f {
  margin-right: 55px;
  font-size: 16px;
}

#username-and-edit-button {
  /* border: brown solid 1px; */
  display: flex;
  justify-content: space-around;
  align-items: center;
}

#bio-container {
  /* border: greenyellow solid 1px; */
  color: #c0c0c0;
}

#biography {
  overflow-wrap: auto;
  word-break: break-word;
}

#username-font {
  margin-right: 40px;
  font-size: 30px;
  text-decoration: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

#user-full-name {
  font-weight: bold;
  margin-bottom: 5px;
}

#username-and-edit-button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
}

/* edit button */
#profile-edit-button {
  color: #fafafa;
  background-color: #121212;
  border-radius: 4px;
  border: 2px solid rgb(54, 54, 54);
  height: 40px;
  width: 95px;
  font-weight: bold;
  font-size: 15px;
  white-space: nowrap;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

#profile-pic-left {
  object-fit: cover;
  border-radius: 50%;

  height: 150px;
  width: 150px;
  /* border: #262626 solid 1px; */
}

#user-info-block {
  /* border: green solid 1px; */
  height: 150px;
  width: 600px;
}

#gallery-line {
  height: 1px;
  width: 925px;
  background-color: #434343;
  /* margin-top: px; */
}

#profile-nav-bar {
  /* border: purple solid 1px; */
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#user-profile-nav-bar {
  font-size: 20px;
}

#postGridIcon {
  width: 20%;
  margin-right: 5px;
}

.postsIconLabel {
  width: 100px;
  display: flex;
  margin-top: 5px;
}

#grid-logo {
  height: 100%;
  width: 100%;
}

/* #grid-logo{
    position: relative;
    top: 5px;
    height: 20px;
    width: 20px;
} */

#gallery {
  padding: 30px 0px;
  /* border: pink solid 5px; */
  /* width: 900px; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-posts {
  display: grid;
  justify-items: center;
  justify-content: center;
  grid-template-columns: 1fr 1fr 1fr;
  width: 900px;
  row-gap: 30px;
  column-gap: 30px;
}

.one-post {
  height: 293px;
  width: 293px;
  object-fit: contain;
  background-color: black;
  /* opacity: 100%; */
}

.one-post:hover {
  opacity: 0.5;
}


/* Modal for followers/following */
.postOptionsModalBckgFeed {
  background-color: rgba(0, 0, 0, 0.504);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
}

.actualModalComponentFeed {
  position: fixed;
  background-color: rgb(29, 29, 29);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: 40%;
  top: 20%;
  width: 300px;
  height: fit-content;
  border: 1px solid rgba(128, 128, 128, 0.346);
  border-radius: 5px;
  z-index: 40;
  animation: grow 0.3s forwards;
}


.follow-button{
  border: none;
  background-color: #037afb;
  color: white;
  width: 6rem;
  height: 2rem;
  border-radius: 5px;
  font-weight: bolder;
  font-size: larger;

}
