/* TODO Add site wide styles */
* {
    margin: 0px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Open Sans', 'Helvetica', Arial, sans-serif;
    color: white;
}

html {
    background-color: rgb(18,18,18);
}

#profile-container {

}

.under-nav {
    padding-top: 60px;
    /* margin-top: 60px; */
}

input{
    padding-left: 5px;
}

button:hover, ._ab6-:hover {
    cursor: pointer;
}


