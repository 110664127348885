
.signup-label {
  display: none;
}

#signup-holder {
  /* border: red solid 3px; */
  height: 800px;
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;

}

#s-logo{
  /* border: red solid 1px; */
  height: 60px;
  width: 200px;
}

/* holds top-logo-container, or divider, and signup-form  */
#top-container {
  background-color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

#s-border{
  border: #363636 solid 1px;
  width: 383px;
  height: 637px;
}

#signup-container {
  position: absolute;
  top: 0;
  font-family: Neue Arial, Helvetica, sans-serif;
  width: 100%;
  height: 100vh;
  /* border: orange solid 4px; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.top-logo-container {
  width: 380px;
  height: 200px;
  /* border: green solid 2px; */
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  text-align: center;
  background-color: #000000;
}

.signup-form-container {
  /* margin-top: 6px; */
  /* border: pink solid 2px; */
  height: 420px;
  width: 380px;
  display: flex;
  justify-content: center;
  align-items: space-evenly;
  flex-direction: column;
  background-color: #000000;
}

#errors {
  color: rgb(196, 30, 30);
  text-align: center;
}

#signup-form {
  /* margin-top:px; */
  padding-top: 10px;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: #000000;
}

.login-container {
  background-color: #000000;
  height: 50px;
  border: #363636 solid 1px;
  width: 380px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.s-input {
  width: 330px;
  height: 30px;
  border: none;
  border-radius: 2px;
  background-color: #121212;
  border: #585858 solid 1px;
}

.s-input:focus{
  outline: none;
}

.s-input::placeholder{
  padding-left: 5px;
}

#disclaimer {
  /* border: yellow solid 3px; */
  width: 300px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}

.disc-text {
  /* position: relative; */
  color: #8E8E8E;
  font-size: 12px;
  line-height: 18px;
  padding: 0px;
  margin-bottom: 5px;
  /* padding-top: 30px; */
}

.s-button {
  background-color: #3795F6;
  color: white;
  width: 330px;
  height: 30px;
  border: none;
  border-radius: 2px;
  cursor: pointer;
}
.s-button:disabled,
.s-button[disabled] {
  background-color: #072641;
  color: gray;
  cursor:default;

}



/* .s-button:disabled,
.s-button[disabled] {
  background-color: #072641;
} */

#login-link {
  text-decoration: none;
  color: #0095f6;
}

#s-text{
  color: #F0F0F0;
}

#heading {
  font-family: 'Oleo Script', cursive;
  font-size: 60px;
  color: #F6F6F6;
}

#text {
  color: #8E8E8E;
}

#or {
  color: #8e8e8e;
}

/* Or divider */
#or-divider {
  padding: 0px;
  margin: 0px;
  height: 15px;
  width: 380px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  background-color: #000000;
  /* border: purple solid 2px; */
}

.line {
  height: 1px;
  width: 100px;
  background-color: #8e8e8e;
}

#signupBtn:hover {
  cursor: pointer;
}

#signupBtn:disabled:hover {
  cursor: default;
}
